<template lang="pug">
  v-container
    v-row
      v-col
        v-layout(
          flex
          justify-space-between
          align-center
        )
          v-btn.mb-5(
            outlined
            :to="{ name: 'drawers' }"
          )
            v-icon(left) mdi-arrow-left
            | Drawers
        v-col.px-0
          h1.text-h4 {{ drawer.name }}
    v-row
      v-col(
        cols="12"
        lg="9"
      )
        v-layout(
          flex
          justify-space-between
        )
          v-btn.my-2(
            outlined
            :to="{ name: 'drawer', params: { drawerId: getPreviousPage() } }"
          )
            v-icon(left) mdi-arrow-left
            | Prev
          CartButton
          v-btn.my-2(
            outlined
            :to="{ name: 'drawer', params: { drawerId: getNextPage() } }"
          ) Next
            v-icon(right) mdi-arrow-right
      v-col(
        cols="12"
        lg="3"
      )
        Dialog.px-0.my-auto(
          :on="dialog"
          @change="addRow"
          :onDecode="onDecode"
          :scannerEnabled="true"
          class="media-btn"
        )
      v-col(
        cols="12"
        lg="9"
      )
        .grid-container(
          :style="gridStyle"
        )
          SectionTile(
            v-if="getSection(n)"
            v-for="(n, index) in totalSections"
            :key="index"
            :section="getSection(n)"
            :part="getSection(n) ? getProduct(getSection(n).pn) : null"
            :style="{ gridColumnEnd: `span ${getWidth(n)}`, gridRowEnd: `span ${getHeight(n)}`}"
          )
        AdditionalComments
      v-col(
        cols="12"
        lg="3"
      )
        h3.text-h5.mb-4 Additional Items
        AdditionalItem(
          v-for="(item, index) in additionalItems"
          :key="index"
          :part="getProduct(item.pn)"
        )
          v-card-title {{ item.pn }}

        h3.text-h5.mt-16.mb-4 Notes
        ul
          li(
            v-for="(note, index) in notes"
          ) {{ note.note }}
          v-btn.px-2.my-1(
            color="secondary"
            large
            block
            v-if="navigation" v-for="(nav, index) in navigation" :to="{ name: 'drawer', params: { drawerId: nav } }") {{nav}}
            v-icon.ml-auto(right) mdi-arrow-right
</template>

<script>
import Dialog from '@/components/Dialog'
import CartButton from '@/components/CartButton'
import parts from '@/data/parts.json'
import SectionTile from '@/components/SectionTile'
import AdditionalItem from '@/components/AdditionalItem'
import AdditionalComments from '@/components/AdditionalComments'

export default {
  name: 'Drawer',
  components: {
    SectionTile,
    AdditionalItem,
    AdditionalComments,
    Dialog,
    CartButton
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    drawers () {
      return this.data.drawers
    },
    drawer () {
      return this.drawers.filter(drawer => drawer.slug === this.$route.params.drawerId)[0]
    },
    additionalItems () {
      return this.drawer.additional
    },
    notes () {
      return this.drawer.notes
    },
    navigation () {
      return this.drawer.navigation
    },
    columns () {
      return this.drawer.cols
    },
    rows () {
      return this.drawer.rows
    },
    sections () {
      return this.drawer.sections
    },
    totalSections () {
      const sections = this.sections.map(section => section.w + section.h)
      const reducer = (accumulator, currentValue) => accumulator + currentValue
      return sections.reduce(reducer)
    },
    parts () {
      return this.data.parts
    },
    gridStyle () {
      return {
        gridTemplateColumns: 'repeat(' + this.columns + ', 1fr',
        gridTemplateRows: 'repeat(' + this.rows + ', 1fr'
      }
    },
    scannedItem (result) {
      const { OrderRow } = this.$FeathersVuex.api
      const { data: items } = OrderRow.findInStore({
        query: { partNumber: result, __isTemp: true },
        temps: true
      })
      return items[0] || null
    }
  },
  methods: {
    lzrScanner (result) {
      if (/^[A-Za-z0-9]$/.test(result.key)) {
        this.lzrScannedProduct = this.lzrScannedProduct + result.key
      }
      if (result.code === 'Enter' || result.code === 'Tab') {
        // this.lzrScannedProduct = 'PN1750'
        this.onDecode(this.lzrScannedProduct.toUpperCase())
        // this.scannedItem('PN1750')
        this.lzrScannedProduct = ''
      }

      return result
    },
    addToCart ({ scannedProduct }) {
      const orderRows = this.$store.state['order-rows'].tempsById
      let doesScannedProductExistInCart = false
      for (const key in orderRows) {
        if (orderRows[key].partNumber === scannedProduct.pn) {
          doesScannedProductExistInCart = true
          if (doesScannedProductExistInCart && orderRows[key].qty === 4) {
            orderRows[key].remove()
          } else {
            orderRows[key].qty++
          }
        }
      }
    },
    onDecode (result) {
      const scannedProduct = this.getProduct(result)

      this.addToCart({ scannedProduct: { drawerSection: this.sections.pn === result ? this.sections.pn.drawerLabel : null, ...scannedProduct } })

      // this.dialog = false
    },
    getProduct (partNumber) {
      return this.parts.filter(part => part.pn === partNumber)[0]
    },
    getSection (index) {
      index = index - 1
      let y = 0
      while (index >= (y + 1) * (this.columns)) {
        y++
      }

      const x = index % this.columns

      const rowProducts = this.sections.filter(section => section.y === y)

      const product = rowProducts.filter(product => product.x === x)[0] || null
      return product
    },
    getWidth (index) {
      const section = this.getSection(index)

      if (!section) return null

      return section.w
    },
    getHeight (index) {
      const section = this.getSection(index)

      if (!section) return null

      return section.h
    },
    getNextPage () {
      const drawers = parts.drawers
      let nextDrawer
      for (let i = 0; i < drawers.length; i++) {
        if (i + 1 !== drawers.length && drawers[i].slug === this.$route.params.drawerId) {
          nextDrawer = drawers[i + 1].slug
          if (i !== 0 && (i + 1) % 5 === 0 && i + 1 !== 35) {
            nextDrawer = drawers[i + 6].slug
            if ((i + 1) % 15 === 0 && i + 1 !== 30) {
              nextDrawer = drawers[i - 9].slug
            } else if ((i + 1) % 20 === 0) {
              nextDrawer = drawers[i + 1].slug
            }
          } else if ((i + 1) % 35 === 0) {
            nextDrawer = drawers[i - 9].slug
          }
        }
      }
      return nextDrawer
    },
    getPreviousPage () {
      const drawers = parts.drawers
      let prevDrawer
      for (let i = 0; i < drawers.length; i++) {
        if (i !== 0 && drawers[i].slug === this.$route.params.drawerId) {
          prevDrawer = drawers[i - 1].slug
          if (i + 1 === 6 || i + 1 === 26) {
            prevDrawer = drawers[i + 9].slug
          }
          if (i + 1 === 36 || i + 1 === 16 || i + 1 === 11 || i + 1 === 31) {
            prevDrawer = drawers[i - 6].slug
          }
          if (i + 1 === 21) {
            prevDrawer = drawers[i - 1].slug
          }
        }
      }
      return prevDrawer
    }
  },
  data: () => ({
    data: parts,
    lzrScannedProduct: ''
  })
}
</script>

<style lang="scss" scoped>
  .grid-container {
    gap: 15px;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    @media only screen and (max-width: 600px) {
      gap: 1px;
    }
  }
</style>
