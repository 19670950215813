<template lang="pug">
.section
  .product.d-flex.flex-column(v-if="section !== undefined", @click="addToCart")
    v-badge.quantity(:value="item", color="success", left, large, overlap)
      template(#badge)
        strong {{ quantity }}
    v-badge.quantity(
      style='cursor: pointer'
      :value="item"
      color="success"
      right
      large
      overlap
      @click.native.stop="removeFromCart"
    )
      template(#badge)
        v-icon mdi-close
    h3.text-center.py-2(v-if="section.pn") {{ section.pn }}
    img.image.grow(v-if="section.pn", :src="`/parts/${part.img}`")
    v-layout.pa-2.details.shrink.mt-auto(flex, justify-space-between)
      strong(v-if="section.sectionNo") {{ section.sectionNo }}
      span(v-if="part && part.qty") QTY {{ part.qty }}
  .blank(v-else)
</template>

<script>
export default {
  name: 'SectionTile',
  props: {
    section: {
      type: Object,
      required: false
    },
    part: {
      type: Object,
      required: false
    }
  },
  computed: {
    item () {
      const { OrderRow } = this.$FeathersVuex.api
      const { data: items } = OrderRow.findInStore({
        query: { partNumber: this.section.pn, __isTemp: true },
        temps: true
      })

      return items[0] || null
    },
    quantity () {
      return this.item ? this.item.qty : 0
    }
  },
  methods: {
    addToCart () {
      if (!this.section.pn) {
        return
      }

      const { OrderRow } = this.$FeathersVuex.api

      if (!this.quantity) {
        // eslint-disable-next-line no-unused-vars
        const row = new OrderRow({ qty: 1, partNumber: this.section.pn, drawerLabel: this.section.drawerLabel })
      } else if (this.quantity === 4) {
        this.item.remove()
      } else {
        this.item.qty++
      }
    },
    removeFromCart () {
      this.item.remove()
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Hemi";
  src: url('/fonts/hemi_head_bd_it.ttf');
}
.section {
  background-color: white;
}

.image {
  max-height: 60px;
  max-width: 60px;
  object-fit: contain;
  margin: 0 auto;
}

.product {
  height: 100%;
}
</style>
